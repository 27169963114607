// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", function() {
  window.scrollTo(0,0)
  let topScroll = document.getElementById('scroll-top-btn');
  let scrollToFooter = document.querySelectorAll('.scroll-to-footer');
  let scrollToTopics = document.querySelectorAll('.scroll-to-topics');
  let topicsSection = document.querySelectorAll('.shape-1')[0];
  let burgerMenu = document.querySelectorAll('.burger-menu')[0];
  let mobileMenu = document.querySelectorAll('.left-menu')[0];
  let flashMessage = document.querySelectorAll('.notification-container')[0];
  
  window.addEventListener('scroll', () => {
    topScroll.classList.remove("hidden-top-scroll");
  });
  
  topScroll.addEventListener('click', () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  });

  scrollToFooter.forEach(item => {
    item.addEventListener('click', () => {
      window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
    })
  })

  scrollToTopics.forEach(item => {
    item.addEventListener('click', () => {
      topicsSection.scrollIntoView({behavior: 'smooth'});
    })
  })

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  burgerMenu.addEventListener('click', () => {
    if (mobileMenu.style.display === "none") {
      mobileMenu.style.display = "block";
    } else {
      mobileMenu.style.display = "none";
    }
  })

  function scrollTo(hash) {
    location.hash = "#" + hash;
  }
  
  function fadeNotificationOut() {
    if (flashMessage) {
      flashMessage.classList.add("notification-invisible");
    }
  }

  setTimeout(fadeNotificationOut, 5000);

});